<template>
<div>

  <section class="signup-section" id="signup">
    <div class="container px-4 px-lg-5">
      <div class="row gx-4 gx-lg-5">
        <div class="col-md-10 col-lg-8 mx-auto text-center">
          <!--i class="far fa-paper-plane fa-2x mb-2 text-bdlg"></i-->
          <h1 class="text-bdlg mb-5">{{contactoTitle[lang]}}</h1>
          <form class="form-signup" id="contactForm" data-sb-form-api-token="API_TOKEN">
            <div class="row input-group-newsletter">
              <div class="col"><input class="form-control" id="emailAddress" type="email" :placeholder="contactoPlaceholder[lang]" aria-label="Enter email address..." data-sb-validations="required,email" /></div>
              <div class="col-auto"><button class="btn btn-bdlg disabled" id="submitButton" type="submit">{{contactoBoton[lang]}}</button></div>
            </div>
            <div class="invalid-feedback mt-2" data-sb-feedback="emailAddress:required">An email is required.</div>
            <div class="invalid-feedback mt-2" data-sb-feedback="emailAddress:email">Email is not valid.</div>
            <div class="d-none" id="submitSuccessMessage">
              <div class="text-center mb-3 mt-2 text-bdlg">
                <div class="fw-bolder">Form submission successful!</div>
                To activate this form, sign up at
                <br />
                <a href="https://startbootstrap.com/solution/contact-forms">https://startbootstrap.com/solution/contact-forms</a>
              </div>
            </div>
            <div class="d-none" id="submitErrorMessage">
              <div class="text-center text-danger mb-3 mt-2">Error sending message!</div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <section class="contact-section bg-white">
    <div class="container ppx-4 ppx-lg-5">
      <div class="row">
        <div class="col-xs-12 col-sm-6">
          <iframe class="gmap" src="https://www.google.com/maps/d/embed?mid=1CYFjsbsU_XIlGLeqjdCavMCv97qsaA4&ehbc=2E312F"></iframe>
        </div>
        <div class="col-xs-12 col-sm-6">
          <div class="row gx-4 gx-lg-5">
            <div class="col-md-12 col-xxl-4 mb-3 mb-md-0">
              <div class="card py-4 h-100">
                <div class="card-body text-center">
                  <i class="fas fa-map-marked-alt text-bdlg mb-2"></i>
                  <h4 class="text-uppercase m-0">Dirección</h4>
                  <hr class="my-4 mx-auto" />
                  <div class="small text-black-50">{{empresa.direccion}}</div>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-xxl-4 mb-3 mb-md-0">
              <div class="card py-4 h-100">
                <div class="card-body text-center">
                  <i class="fas fa-envelope text-bdlg mb-2"></i>
                  <h4 class="text-uppercase m-0">Email</h4>
                  <hr class="my-4 mx-auto" />
                  <div class="small text-black-50"><a :href="'mailto:'+empresa.email">{{empresa.email}}</a></div>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-xxl-4 mb-3 mb-md-0">
              <div class="card py-4 h-100">
                <div class="card-body text-center">
                  <i class="fas fa-mobile-alt text-bdlg mb-2"></i>
                  <h4 class="text-uppercase m-0">Teléfono</h4>
                  <hr class="my-4 mx-auto" />
                  <div class="small text-black-50">
                    <a :href="'tel:'+empresa.telefono">{{empresa.telefono}}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
          </div>
        </div>
      </div>
      <div class="social d-flex justify-content-center">
        <a class="mx-2" href="#!"><i class="fab fa-twitter"></i></a>
        <a class="mx-2" href="#!"><i class="fab fa-facebook-f"></i></a>
        <a class="mx-2" href="#!"><i class="fab fa-github"></i></a>
      </div>
    </div>
  </section>

</div>
</template>
<style>
.gmap {
  width: 100%;
  height: 100%;
}

.signup-section {
  padding: 10rem 0;
  background-color: white;
}

.signup-section .form-signup input {
  box-shadow: 0 0.1875rem 0.1875rem 0 rgba(0, 0, 0, 0.1) !important;
  padding: 1.25rem 2rem;
  height: auto;
  font-family: var(--bs-font-serif), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 80%;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  border: 0;
}

.contact-section {
  padding-top: 5rem;
}

.contact-section .card {
  border: 0;
  border-bottom: 0.15rem solid #8e7235;
}

.contact-section .card h4,
.contact-section .card .h4 {
  font-size: 0.8rem;
  font-family: var(--bs-font-serif), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  text-transform: uppercase;
  letter-spacing: 0.15rem;
}

.contact-section .card hr {
  border-color: #8e7235;
  border-width: 0.25rem;
  width: 3rem;
}

.contact-section .social {
  margin-top: 5rem;
}

.contact-section .social a {
  text-align: center;
  height: 3rem;
  width: 3rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 100%;
  line-height: 3rem;
  color: rgba(255, 255, 255, 0.3);
}

.contact-section .social a:hover {
  color: rgba(255, 255, 255, 0.5);
}

.contact-section .social a:active {
  color: #fff;
}

.signup-section h1 {
  margin-bottom: 2px solid #8e7235;
}

input,
button {
  text-transform: none !important;
}
</style>
<script>
import Api from '@/components/Api'
export default {
  name: 'Contacto',
  data() {
    return {
      lang: Api.obtenerLocal('lang') || 'es',
      empresa: {
        nombre: 'BDLG',
        nombreLargo: 'Bufete de la Garza',
        logo: '../assets/512w/logo.png',
        iso: '../assets/512w/iso.png',
        isogris: '../assets/512w/isogris.png',
        completo: '../assets/512w/completo.png',
        slogan: {
          es: 'Firma de abogados',
          en: 'Lawers firm'
        },
        email: 'contacto@bdlg.mx',
        direccion: 'Humboldt No. 121, Colonia del Valle, 78200 SLP ',
        telefono: '444 811 4069',
      },
      contactoTitle: {
        es: 'Contáctenos',
        en: 'Contact us'
      },
      contactoPlaceholder: {
        es: 'Su correo electrónico',
        en: 'Your email'
      },
      contactoBoton: {
        es: 'Enviar',
        en: 'Send'
      }
    }
  }
}
</script>
