<template>
<div class="text-justify about-ux">
  <p v-if="lang=='es'">Bufete de la Garza, S.C. es una firma de abogados con más de tres décadas de experiencia, durante las cuáles ha brindando servicios legales de la más alta calidad y de forma integral a todos sus clientes.</p>
  <p v-else>Bufete de la Garza, S.C. is a law firm with more than three decades of experience, rendering high quality, integral law services to all of its clients.</p>
  <p v-if="lang=='es'">
    Nuestra filosofía consiste en brindar servicios profesionales que estructuren propuestas de solución efectiva a las necesidades legales de nuestros clientes, todo ello apegado a las normas de la ética profesional.</p>
  <p v-else>Our philosophy consists in rendering professional services that grant effective solutions to every legal needs of our customers, while getting aligned with professional ethics norms.</p>
  <p class="selectmodo pt-5 pb-5">
    <a @click="modo='Mision'" :class="{'seleccionado':modo=='Mision'}">{{modos['Mision'][lang]}}</a>
    <a @click="modo='Vision'" :class="{'seleccionado':modo=='Vision'}">{{modos['Vision'][lang]}}</a>
    <a @click="modo='Valores'" :class="{'seleccionado':modo=='Valores'}">{{modos['Valores'][lang]}}</a>
  </p>
  <p class="w-100">&nbsp;</p>
  <p v-if="typeof copy[lang][modo] == 'string'">{{copy[lang][modo]}}</p>
  <div v-else>
    <p v-for="(v,indice) in copy[lang][modo]" :key="indice">{{v}}</p>
  </div>
</div>
</template>
<style>
.selectmodo a {
  margin-right: 1em;
  padding: 0.5em;
  border-radius: 6px;
  text-transform: uppercase;
  display: block;
  float: left;
}

.selectmodo .seleccionado {
  color: #ccc;
  background-color: #ddd;
}
</style>
<script>
import Api from '@/components/Api'
export default {
  name: 'Acerca',
  data() {
    return {
      lang: Api.obtenerLocal('lang') || 'es',
      modo: 'Valores',
      modos: {
        Mision: {
          es: 'Misión',
          en: 'Mission'
        },
        Vision: {
          es: 'Visión',
          en: 'Vision'
        },
        Valores: {
          es: 'Valores',
          en: 'Values'
        }
      },
      copy: {
        es: {
          'Mision': 'Ser la firma de mayor trascendencia jurídica corporativa en México.',
          'Vision': 'Proporcionar un servicio de asesoría jurídica de la más alta calidad en las diferentes ramas del derecho guardando los más altos estándares éticos y de excelencia.',
          'Valores': ['Honestidad', 'Lealtad', 'Integridad', 'Responsabilidad', 'Compromiso']
        },
        en: {
          'Mision': 'To be the most trascendent corporate law firm in Mexico',
          'Vision': 'To provide a top quality juridic assessment service in every law branch, while maitaining the highest standards of excellency and ethics',
          'Valores': ['Honesty', 'Loyalty', 'Integrity', 'Responsability', 'Commitment']
        }
      }
    }
  }
}
</script>
