<template>
<div class="pdfwrap">
  <embed class="pdf" type="application/pdf" :src="ruta+'#toolbar=0&navpanes=0&scrollbar=1'" />
</div>
</template>
<script>
export default {
  name: 'ManualIntegridad',
  data() {
    return {
      ruta: './documentos/estandaresprobono.pdf'
    }
  }
}
</script>
<style scoped>
.pdfwrap {
  width: 100%;
}

.pdf {
  width: 100%;
  min-height: calc(100vh - 200px);
}
</style>
