<template>
<section class="membresia-section">
  <div class="container px-4 px-lg-5 d-flex h-100 align-items-center justify-content-center">
    <div class="row">
      <div class="col-12">
        <h1 class="titulomembresia text-center mx-auto text-bdlg">
          Miembros de</h1>
      </div>
      <div class="col-xs-12 col-sm-3 membresias" v-for="m in empresa.membresias" :key="m">
        <img :src="'../assets/membresias/'+m+'.png'" />
      </div>
    </div>
  </div>
</section>
</template>
<style>
h1.titulomembresia {
  font-size: 2.5em;
  padding-bottom: 1.5em;
  margin-bottom: 2px solid #8e7235 !important;
}

.membresias {
  text-align: center;
  height: 25vh;
  vertical-align: middle;
}

.membresialogo {
  height: 1em;
  margin-top: -5px;
}

.membresias img {
  padding: 10px;
  max-width: 200px;
  width: 100%;
}
</style>
<script>
export default {
  name: 'Membresias',
  props: {
    lang: {
      type: String,
      default: 'es'
    }
  },
  data() {
    return {
      empresa: {
        membresias: ['ABA', 'anade', 'BMA', 'Colegio-de-abogados', 'ICC', 'LM', 'UIA']
      }
    }
  }
}
</script>
