<template>
<div class="hello">

  <div class="errorpage">
    <div class="container container-fluid px-4 px-lg-5 dd-flex h-100 align-items-center justify-content-center">
      <div>
        <div class="bg-white half2">
          <div>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <h1>404!</h1>
            <p>Lo sentimos, esta página no existe. Quieres <a href="#/">ir al inicio</a>?</p>
          </div>
        </div>
        <div class="half1" v-bind:style="{backgroundImage:'url('+post.imagen+')'}">
        </div>
      </div>
    </div>
  </div>

</div>
</template>
<style scoped>
.errorpage {
  width: 100vw;
  height: 100vh;
}

.blog-container {
  display: block;
  width: 100%;
  min-height: 100vh;
  padding: 10px;
}

.half1 {
  width: 50vw;
  height: 100%;
  background-color: #cccccc;
  background-position: center;
  background-size: cover;
  position: fixed;
  top: 0px;
  left: 0px;
  padding: 3em;
  padding-top: 70px;
  overflow-y: auto;
  border: 2px solid #cccccc;
  opacity: 0.5;
}

.half2 {
  width: 50vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 50vw;
  padding: 4em;
  padding-top: 80px;
  overflow-y: auto;
}

@media(max-width:600px) {
  .half1 {
    display: none;
  }

  .half2 {
    width: 100vw;
    left: 0vw;
  }
}
</style>
<script>
import Api from '@/components/Api'
export default {
  name: 'areas',
  props: ['lang'],
  data() {
    return {
      empresa: Api.obtenerLocal('bdlg.mx.empresa') || {},
      esMovil: Api.esMovil(),
      post: {
        contenido: {
          //es: 'Hola mundo'
        },
        imagen: 'https://generative-placeholders.glitch.me/image?width=1000&height=1000'
      }
    }
  }
}
</script>
