<template>
<div class="text-justify about-uxw">
  <p v-if="lang=='es'">Bufete de la Garza S.C. busca la excelencia en su obligación social, por lo que la presentación de sus servicios profesionales está alineada con los estándares de Pro Bono México.</p>
  <p v-else>Bufete de la Garza S.C. seeks excelence in its social obligations, which is why its professional services rendering is tied with the Pro bono mexican standards. </p>
  <p v-if="lang=='es'">
    Buscamos establecer las bases, motivos y consideraciones para estandarizar el trabajo legal pro bono en México.</p>
  <p v-else>We look forward to establish bases, motivations and considerations in order to standardize pro bono law in Mexico.</p>
  <h2 class=" pt-3 text-center text-bdlg" v-if="lang=='es'">¿A quíen va dirigido?</h2>
  <h2 class=" pt-3 text-center text-bdlg" v-else>¿Who is it adressed to?</h2>
  <div class="row targets">
    <div class="col-xs-12 col-md-6 col-lg-4 text-center">
      <img src="assets/nocash.png" />
      <p v-if="lang=='es'">Personas o comunidades de escasos recursos o en condición de vulnerabilidad</p>
      <p v-else>People and communities with low resources or in vulnerable situation</p>
    </div>
    <div class="col-xs-12 col-md-6 col-lg-4 text-center">
      <img src="assets/hands.png" />
      <p v-if="lang=='es'">Organizaciones de la sociedad civil que asisten a las anteriores.</p>
      <p v-else>Non-profit, social organizations wich help the latter</p>
    </div>
    <div class="col-xs-12 col-md-6 col-lg-4 text-center">
      <img src="assets/city.png" />
      <p v-if="lang=='es'">Personas, organizaciones e instituciones en asuntos de interés público que no cuenten con los recursos para acceder a una representación o asesoría jurídica</p>
      <p v-else>People, organizations and institutions dealing with public interest affairs that cant afford law representation or assessment</p>
    </div>
    <div class="col-xs-12 col-md-6 col-lg-4 text-center">
      <img src="assets/teach.png" />
      <p v-if="lang=='es'">Emprendedores o empresas sociales que no cuenten con fondos de manera inmediata y que de otra manera no tendrían una representación o asesoría legal efectiva para el desarrollo de sus proyectos</p>
      <p v-else>Entrepreneurs or non-profit organizations that don´t count with immediate funding and otherwise couldn´t have legal representation or assessment</p>
    </div>
    <div class="col-xs-12 col-md-6 col-lg-4 text-center">
      <img src="assets/coin.png" />
      <p v-if="lang=='es'">Organizaciones autorizadas para recibir donativos deducibles de impuestos, que de otra forma no tendrían una representación o asesoría legal efectiva</p>
      <p v-else>Organizations that are allowed to get tax-free funds, which otherwise couldn´t afford legal representation or assessment</p>
    </div>
  </div>
  <p v-if="lang=='es'">Para mayor información, puede acceder a la página:</p>
  <p v-else>To get more info, you can access this website:</p>
  <p><a href="https://estandaresprobono.mx" target="_blank">www.estandaresprobono.mx</a></p>
  <p v-if="lang=='es'">Asimismo puede consultar directamente los estándares Pro Bono México a través del siguiente enlace:</p>
  <p v-else>You can check directly Pro Bono Mexican standards as well:</p>
  <p>
    <a href="/#" @click="irA">Estandares Probono Mexico PDF</a>
  </p>
</div>
</template>
<style>
.targets {
  padding: 1em;
}

.targets img {
  width: 60%;
  margin-bottom: 1em;
}
</style>
<script>
import Api from '@/components/Api'
export default {
  name: 'Probono',
  data() {
    return {
      lang: Api.obtenerLocal('lang') || 'es'
    }
  },
  methods: {
    irA() {
      Api.$emit('seleccionarComponente', {
        componente: 'estandaresprobono'
      })
    }
  }
}
</script>
