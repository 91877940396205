<template>
<!--section class="about-section text-center align-items-center justify-contents-center" id="about">
  <div class="container px-4 px-lg-5 d-flex align-items-center justify-contents-center">
    <div class="row gx-4 gx-lg-5 justify-content-center about-mid">
      <div class="col-lg-7 col-md-6 col-sm-12">
        <div class="text-justify about-uxw">
          <p>Bufete de la Garza, S.C. es una firma de abogados con más de tres décadas de experiencia, durante las cuáles ha brindando servicios legales de la más alta calidad y de forma integral a todos sus clientes.</p>
          <p>
            Nuestra filosofía consiste en brindar servicios profesionales que estructuren propuestas de solución efectiva a las necesidades le- gales de nuestros clientes, todo ello apegado a las normas de la ética profesional.</p>
        </div>
      </div>
      <div class="col-lg-5 col-md-6 col-sm-12 about-imgs">
        <img src="../../public/g926.png" class="logoheader detrasdearcangel" />
        <img src="../../public/g1346.png" class="arcangel" />
      </div>
    </div>
  </div>
</section-->

<section class="about-section text-center align-items-center justify-contents-center" id="about">
  <div class="container px-4 px-lg-5 d-flex align-items-center justify-contents-center">
    <div class="text-justify about-uxw">
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p v-if="lang=='es'">Bufete de la Garza, S.C. es una firma de abogados con más de tres décadas de experiencia, durante las cuáles ha brindando servicios legales de la más alta calidad y de forma integral a todos sus clientes.</p>
      <p v-else>Bufete de la Garza, S.C. is a law firm with more than three decades of experience, during which it has provided legal services of the highest quality and comprehensively to all its clients.</p>
      <p v-if="lang=='es'">
        Nuestra filosofía consiste en brindar servicios profesionales que estructuren propuestas de solución efectiva a las necesidades legales de nuestros clientes, todo ello apegado a las normas de la ética profesional.</p>
      <p v-else>Our philosophy is to provide professional services that structure proposals for effective solutions to the legal needs of our clients, while aligned with professional ethics norms.</p>
    </div>
  </div>
</section>
</template>
<style>
.aboutsection {
  background-color: #dee1c7;
  height: 100vh;
  width: 100vw;
}

.about-section {
  background-color: #dee1c7;
}

.detrasdearcangel {
  opacity: 0.15;
  right: 25%;
  width: 100%;
}

.about-section .arcangel {
  min-width: 50%;
  max-width: 100%;
  z-index: 100;
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.about-uxw {
  font-size: 1em;
  border-left: 4px solid #8e7235;
  width: 100%;
  padding-left: 2em;
  padding-right: 4em;
  margin-bottom: 220px;
}

.about-mid {
  padding-top: 220px;
}

.about-imgs {
  //width: 100%;
}

@media (max-width: 600px) {
  .about-section .arcangel {
    position: relative;
    float: left;
  }

  .about-uxw {
    padding-left: 1em;
    padding-right: 1em;
  }
}
</style>
<script>
import Api from '@/components/Api'
export default {
  name: 'Resumen',
  data() {
    return {
      lang: Api.obtenerLocal('lang') || 'es'
    }
  }
}
</script>
