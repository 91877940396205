<template>
<section class="nosotros text-center align-items-center justify-contents-center" id="about">
  <div class="row">
    <div class="ug col-xs-12 col-md-4 pt-5 px-5 bg-gris h-min-full">
      <p>&nbsp;</p>
      <div class="text-left">
        <p>&nbsp;</p>
        <h3 class="text-bdlg">{{titulo[lang]}}</h3>
        <p>&nbsp;</p>
        <p v-for="l in links" :key="l.ruta">
          <a :href="l.ruta" :class="{'seleccionado':l.componente==seccion}" @click="seleccionarComponente(l)">{{l.label[lang]}}</a>
        </p>
      </div>
    </div>
    <div class="ug col-xs-12 col-md-8 pt-5 h-min-full">
      <p>&nbsp;</p>
      <div class="p-5">
        <Acerca v-if="seccion=='acerca'" :lang="lang" />
        <ManualIntegridad v-if="seccion=='manualintegridad'" :lang="lang" />
        <EstandaresProbono v-if="seccion=='estandaresprobono'" :lang="lang" />
        <CodigoEtica v-if="seccion=='codigoetica'" :lang="lang" />
        <Probono v-if="seccion=='probono'" :lang="lang" />
      </div>
    </div>
  </div>
</section>
</template>
<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.nosotros {
  width: 100vw;
  min-height: 100vh;
  background-color: white;
  //background-image: url(../../public/g858.png);
}

.nosotros .bg-gris {
  background-color: #eeeeee !important;
}

.nosotros .titulo {
  font-size: 3em;
  border-bottom: 1.2px solid #8e7235;
  margin: 0px 0px 1.5em 0px;
}

ul {
  padding-bottom: 3em;
  text-align: left;
  list-style: none;
}

li {
  padding-top: 0.6em;
}

a {
  text-decoration: none;
  color: black;
  text-transform: none;
}

.seleccionado {
  color: #9e7235;
  //text-decoration: overline;
}
</style>
<script>
import Api from '@/components/Api'
import Acerca from './Nosotros/Acerca'
import ManualIntegridad from './Nosotros/ManualIntegridad'
import EstandaresProbono from './Nosotros/EstandaresProbono'
import CodigoEtica from './Nosotros/CodigoEtica'
import Probono from './Nosotros/Probono'
export default {
  name: 'Nosotros',
  components: {
    Acerca,
    ManualIntegridad,
    EstandaresProbono,
    CodigoEtica,
    Probono
  },
  props: {
    seccion: {
      type: String,
      default: 'acerca'
    }
  },
  data() {
    return {
      lang: Api.obtenerLocal('lang') || 'es',
      titulo: {
        es: 'Acerca del bufete',
        en: 'About the firm'
      },
      links: [{
        componente: 'acerca',
        ruta: '#/nosotros/acerca',
        label: {
          es: 'Acerca de la firma',
          en: 'About the firm'
        }
      }, {
        componente: 'probono',
        ruta: '#/nosotros/probono',
        label: {
          es: 'Probono',
          en: 'Probono'
        }
      }, {
        componente: 'manualintegridad',
        ruta: '#/nosotros/manualintegridad',
        label: {
          es: 'Manual de integridad',
          en: 'Integrity guidelines'
        }
      }, {
        componente: 'estandaresprobono',
        ruta: '#/nosotros/estandaresprobono',
        label: {
          es: 'Estándares Pro bono',
          en: 'Pro bono standards'
        }
      }, {
        componente: 'codigoetica',
        ruta: '#/nosotros/codigoetica',
        label: {
          es: 'Código de Ética de la Barra de abogados',
          en: 'Mexican Law bar ethics code'
        }
      }],

    }
  },
  mounted() {
    if (this.lang == 'en')
      this.modo = 'Values'
    console.log("nosotros", this.seccion)
    Api.$on('seleccionarComponente', this.seleccionarComponente)
  },
  methods: {
    seleccionarComponente(l) {
      this.seccion = l.componente
      document.body.scrollTop = document.documentElement.scrollTop = 0
    }
  },
  watch: {
    'seccion': function(a) {
      console.log(a)
    }
  }
}
</script>
