<template>
<!--header class="masthead">
  <div class="container px-4 px-lg-5 d-flex h-100 align-items-center justify-content-center">
    <div class="text-center">
      <h1>
        <div>Lo que nos mueve
          <br />son nuestros clientes
        </div>
      </h1>
    </div>
  </div>
</header-->
<header class="hero">
  <div class="row">
    <div class="col-sm-6 col-xs-12 col1" @click="cambiarIndice">
      <Transition>
        <div class="slide slide0" v-if="indice==0">
          <h1>Lo que nos mueve<br /><b>son nuestros clientes</b></h1>
        </div>
        <div class="slide slide1" v-if="indice==1">
          <p>Nuestra filosofía es prestar servicios profesionales tendientes a estructurar propuestas
            de solución efectiva a las necesidades legales,
            todo ello apegado a las normas de la ética
            profesional.<br /><br /><b>
              Lo que nos mueve son nuestros clientes.</b></p>
        </div>
        <div class="slide slide2" v-if="indice==2">
          <p>
            <img src="assets/SVG/completo.svg" />
          </p>
        </div>
      </Transition>
    </div>
    <div class="col-sm-6 col-xs-12 col2 ">
      <img src="g1346.png" />
    </div>
    <div class="col-xs-12 col4">
      <p><img src="g1346.png" /></p>
    </div>
    <div class="col-xs-12 col5">
      <!--p>
        Nuestra filosofía es prestar servicios profesionales tendientes a estructurar propuestas
        de solución efectiva a las necesidades legales,
        todo ello apegado a las normas de la ética
        profesional.<br /><br /><b>
          Lo que nos mueve son nuestros clientes.</b>
        </p-->
      <img src="assets/SVG/completo.svg" />
    </div>
    <div class="col-xs-12 col3">
      <p>Bufete de la Garza, S.C. Firma de abogados con más de tres décadas de experiencia</p>
    </div>
  </div>
</header>
</template>
<style>
.hero {
  width: 100vw !important;
  min-height: calc(100vh - 72px);
  padding-top: 72px;
  background-color: #d5dbbb;
}

.col1 {
  min-height: calc(100vh - 200px);
  background-color: #ffffff;
}

.col2 {
  background-color: #d5dbbb;
}

.col3 {
  background-color: #93954c;
  height: 140px;
  color: white;
  font-size: 1em;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.col3 p {
  padding: 1em;
}

.col2 img {
  max-width: 40vw;
  max-height: 50vh;
  position: absolute;
  bottom: 0px;
  right: 10vw;
}

.col4,
.col5 {
  display: none;
}

@media (max-width: 768px) {

  .col1,
  .col2 {
    display: none;
  }

  .col4,
  .col5 {
    display: inherit;
  }

  .col4 {
    background-color: #d5dbbb;
    min-height: 20vh;
    height: calc(100vh - 368px);
    position: relative;
  }

  .col4 p {
    text-align: center;
  }

  .col4 p img {
    width: 60%;
    position: absolute;
    bottom: 0px;
    left: 20%;
  }

  .col5 {
    background-color: white;
    height: 20vh;
    min-height: 140px;
    padding: 2em;
    text-align: justify;
  }

  .col5 img {
    display: block;
    width: 100%;
    padding-left: 3em;
    padding-right: 3em;
  }

}

.slide {
  width: 100%;
  height: 100%;
  display: block;
  padding: 2em auto 2em auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.slide0 img {
  padding: 5em;
  margin: auto;
  display: block;
  width: 35vw;
}

.slide1 h1 {
  color: #373639;
}

.slide1 p {
  text-align: center;
  font-size: 1em;
  text-align: justify;
  padding: 2.5em;
}

/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
<script>
export default {
  name: 'Hero',
  data() {
    return {
      indice: 0
    }
  },
  methods: {
    cambiarIndice() {
      this.indice++
      if (this.indice > 1)
        this.indice = 0
    }
  },
  mounted() {
    //setInterval(this.cambiarIndice, 1000)
  }
}
</script>
